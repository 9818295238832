import React from 'react'
import { Menu } from 'semantic-ui-react'
import TouchCounter from '../touches/TouchCounter'
import UserMenu from './UserMenu'

const TopBar = () => {
  return (
    <Menu>
      <Menu.Item header>
        <h2>No Touchy!</h2>
      </Menu.Item>
      <Menu.Item>
        <TouchCounter />
      </Menu.Item>
      <Menu.Item position="right">
        <UserMenu />
      </Menu.Item>
    </Menu>
  )
}

export default TopBar
