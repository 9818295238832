import React from 'react'
import { Label, Segment } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { pipe, prop, sort, map } from 'ramda'
import TouchBadge from './TouchBadge'
import './touchGraph.css'

const sortTouches = (touch1, touch2) => {
  return touch1.at - touch2.at < 0 ? 1 : -1
}

const mapTouches = (touch) => {
  return <TouchBadge key={touch.at} touch={touch} />
}

const showTouches = pipe(sort(sortTouches), map(mapTouches))

const TouchGraph = () => {
  const touches = useSelector((state) =>
    prop('touches', state).sort(sortTouches)
  )
  return (
    <Segment placeholder id="touch-placeholder">
      <Label.Group color="black">{showTouches(touches)}</Label.Group>
    </Segment>
  )
}

export default TouchGraph
