import React, { useState, useEffect } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { getCurrentUser } from '../auth/auth-service'
import { AmplifySignOut } from '@aws-amplify/ui-react'
import { path } from 'ramda'

const UserMenu = () => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (!user) {
      getCurrentUser().then((data) => setUser(data))
    }
  })

  return (
    <Dropdown inline text={path(['attributes', 'email'], user)}>
      <Dropdown.Menu>
        <AmplifySignOut />
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default UserMenu
