import React, { useEffect } from 'react'
import { Container, Divider } from 'semantic-ui-react'
import TouchButton from '../touches/TouchButton'
import TopBar from './TopBar'
import TouchGraph from '../touches/TouchGraph'
import TouchStatistics from '../touches/TouchStatistics'
import { useDispatch } from 'react-redux'
import { listTouches } from '../touches/touch-store'

const AppContent = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const list = async () => {
      const touches = await listTouches()
      dispatch({ type: 'touch/load', payload: touches })
    }
    list()
  })

  return (
    <div>
      <TopBar />
      <Container>
        <TouchButton />
        <TouchGraph />
        <Divider horizontal>Recent</Divider>
        <TouchStatistics />
      </Container>
    </div>
  )
}

export default AppContent
