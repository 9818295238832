import React from 'react'
import { useSelector } from 'react-redux'
import { head, prop } from 'ramda'
import TimeAgo from 'react-timeago'

const recentSort = (a, b) => {
  return b.at - a.at
}

const mostRecent = (touches) => {
  if (touches && touches.length > 1) {
    return head([...touches].sort(recentSort))
  } else if (touches) {
    return head([...touches])
  } else {
    return {}
  }
}

const getTimer = (touch) => {
  if (touch) {
    return <TimeAgo date={new Date(touch.at)} />
  }
  return 'never'
}

const TouchCounter = () => {
  const mostRecentTouch = useSelector((state) =>
    mostRecent(prop('touches', state))
  )

  return (
    <div>
      <span>Since last touch: {getTimer(mostRecentTouch)}</span>
    </div>
  )
}

export default TouchCounter
