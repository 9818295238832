import React from 'react'
import 'semantic-ui-less/semantic.less'
import AppContent from './ui/AppContent'
import { configureStore } from '@reduxjs/toolkit'
import { touchReducer } from './touches/touch-store'
import { Provider } from 'react-redux'
import { withAuthenticator } from '@aws-amplify/ui-react'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
Amplify.configure(awsconfig)

const touchStore = configureStore({ reducer: touchReducer })

const App = () => (
  <Provider store={touchStore}>
    <AppContent />
  </Provider>
)

export default withAuthenticator(App)
