import React from 'react'
import { Card } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'
import TouchStatistic from './TouchStatistic'

const views = [
  { label: 'Last 5 Mintues', filter: '5m' },
  { label: 'Last Hour', filter: '1h' },
  { label: 'Last 24 Hours', filter: '24h' }
]

const TouchStatistics = () => {
  const touches = useSelector((state) => prop('touches', state))

  return (
    <Card.Group>
      {views.map((view) => (
        <TouchStatistic key={view.filter} touches={touches} view={view} />
      ))}
    </Card.Group>
  )
}

export default TouchStatistics
