import React from 'react'
import { Icon, Label } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { deleteTouch } from './touch-store'

const format = (at) => {
  return new Date(at).toUTCString()
}

const TouchBadge = ({ touch }) => {
  const dispatch = useDispatch()

  return (
    <Label>
      {format(touch.at)}
      <Icon
        name="delete"
        onClick={async () => {
          dispatch({ type: 'touch/remove', payload: await deleteTouch(touch) })
        }}
      />
    </Label>
  )
}

export default TouchBadge
