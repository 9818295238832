/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTouch = /* GraphQL */ `
  mutation CreateTouch(
    $input: CreateTouchInput!
    $condition: ModelTouchConditionInput
  ) {
    createTouch(input: $input, condition: $condition) {
      id
      at
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTouch = /* GraphQL */ `
  mutation UpdateTouch(
    $input: UpdateTouchInput!
    $condition: ModelTouchConditionInput
  ) {
    updateTouch(input: $input, condition: $condition) {
      id
      at
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTouch = /* GraphQL */ `
  mutation DeleteTouch(
    $input: DeleteTouchInput!
    $condition: ModelTouchConditionInput
  ) {
    deleteTouch(input: $input, condition: $condition) {
      id
      at
      createdAt
      updatedAt
      owner
    }
  }
`;
