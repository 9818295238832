import { Auth } from 'aws-amplify'
import { path } from 'ramda'

export const getCurrentUser = async (attribute) => {
  try {
    const currentUserInfo = await Auth.currentUserInfo()
    console.log(currentUserInfo)
    if (currentUserInfo) {
      if (attribute) {
        const attributePath = attribute.split('.')
        return path(attributePath, currentUserInfo)
      } else {
        return currentUserInfo
      }
    }
  } catch (err) {
    console.log('could not get current user info', err)
  }
}
