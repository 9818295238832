import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'semantic-ui-react'
import { createTouch } from './touch-store'

const TouchButton = () => {
  const dispatch = useDispatch()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
      }}
    >
      <Button
        size="massive"
        color="red"
        onClick={async () =>
          dispatch({ type: 'touch/add', payload: await createTouch() })
        }
      >
        I touched my face.
      </Button>
    </div>
  )
}

export default TouchButton
