import { append, assoc, path, prop, without } from 'ramda'
import { API } from 'aws-amplify'
import * as mutations from '../graphql/mutations'
import * as queries from '../graphql/queries'

const generateTouch = () => {
  return {
    at: new Date().toISOString()
  }
}

export const listTouches = async () => {
  const touches = await API.graphql({
    query: queries.listTouchs
  })
  console.log('touches', { touches })
  return path(['data', 'listTouchs', 'items'], touches)
}

export const createTouch = async () => {
  const touch = await API.graphql({
    query: mutations.createTouch,
    variables: { input: generateTouch() }
  })
  console.log('created touch', { touch })
  return path(['data', 'createTouch'], touch)
}

export const deleteTouch = async (touch) => {
  console.log('deleting touch..', touch)
  await API.graphql({
    query: mutations.deleteTouch,
    variables: { input: { id: touch.id } }
  })
  return touch
}

export const touchReducer = (state = { touches: [] }, action) => {
  switch (prop('type', action)) {
    case 'touch/load': {
      return assoc('touches', prop('payload', action), state)
    }
    case 'touch/add': {
      return assoc(
        'touches',
        append(prop('payload', action), state.touches),
        state
      )
    }
    case 'touch/remove':
      return assoc(
        'touches',
        without([prop('payload', action)], state.touches),
        state
      )
    default:
      return state
  }
}
