import React from 'react'
import { Card, Statistic } from 'semantic-ui-react'
import Faker from 'faker'
import './touchStatistic.css'

const TwentyFourHoursFilter = (touch) => {
  return Date.now() - 1000 * 60 * 60 * 24 < new Date(touch.at)
}

const FiveMinutesFilter = (touch) => {
  return Date.now() - 1000 * 60 * 5 < new Date(touch.at)
}

const OneHourFilter = (touch) => {
  return Date.now() - 1000 * 60 * 60 < new Date(touch.at)
}

const filterTouches = (touches, filter) => {
  switch (filter) {
    case '24h':
      return touches.filter(TwentyFourHoursFilter)
    case '1h':
      return touches.filter(OneHourFilter)
    case '5m':
      return touches.filter(FiveMinutesFilter)
    default:
      return Faker.random.number()
  }
}

const TouchStatistic = ({ touches, view }) => {
  const relevantTouches = filterTouches(touches, view.filter)

  return (
    <Card className="touch-stat-card">
      <Statistic size="large">
        <Statistic.Value>{relevantTouches.length}</Statistic.Value>
        <Statistic.Label>{view.label}</Statistic.Label>
      </Statistic>
    </Card>
  )
}

export default TouchStatistic
