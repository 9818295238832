/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:74170834-3c51-4049-ac28-28f15c3870b6",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_04zBuALGT",
    "aws_user_pools_web_client_id": "3jaeagji0sa0h61pq8cd5gve5q",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://zjyscgk4xvfwlks7oseyl3wpbu.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
